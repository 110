const DEFAULT = {
    size: 1,
    movement: 4,
    weaponSkill: 3,
    ballisticSkill: 3,
    strength: 3,
    toughness: 3,
    wounds: 1,
    initiative: 4,
    attacks: 1,
    leadership: 7,
    armourSave: 7
};

const isValidUnit = (unit) => {
    if (!unit.movement || unit.movement < 1 || unit.movement > 10) {
        return false;
    }
    if (!unit.weaponSkill || unit.weaponSkill < 1 || unit.weaponSkill > 10) {
        return false;
    }
    if (!unit.ballisticSkill || unit.ballisticSkill < 1 || unit.ballisticSkill > 10) {
        return false;
    }
    if (!unit.strength || unit.strength < 1 || unit.strength > 10) {
        return false;
    }
    if (!unit.toughness || unit.toughness < 1 || unit.toughness > 10) {
        return false;
    }
    if (!unit.attacks || unit.attacks < 1 || unit.attacks > 10) {
        return false;
    }
    if (!unit.armourSave || unit.armourSave < 2 || unit.armourSave > 7) {
        return false;
    }
    return true;
}

const isValidForCharge = (unit) => {
    return !(!unit.movement || unit.movement < 1 || unit.movement > 10);
}

const isValidForShooting = (unit) => {
    if (!unit.ballisticSkill || unit.ballisticSkill < 1 || unit.ballisticSkill > 10) {
        return false;
    }
    if (!unit.strength || unit.strength < 1 || unit.strength > 10) {
        return false;
    }
    return !(!unit.attacks || unit.attacks < 1);
}

const isValidForCombat = (unit) => {
    if (!unit.weaponSkill || unit.weaponSkill < 1 || unit.weaponSkill > 10) {
        return false;
    }
    if (!unit.strength || unit.strength < 1 || unit.strength > 10) {
        return false;
    }
    return !(!unit.attacks || unit.attacks < 1);
}

const isValidForCombatTarget = (unit) => {
    if (!unit.weaponSkill || unit.weaponSkill < 1 || unit.weaponSkill > 10) {
        return false;
    }
    if (!unit.toughness || unit.toughness < 1 || unit.toughness > 10) {
        return false;
    }
    return !(!unit.armourSave || unit.armourSave < 2 || unit.armourSave > 7);
}

const isValidForShootingTarget = (unit) => {
    if (!unit.toughness || unit.toughness < 1 || unit.toughness > 10) {
        return false;
    }
    return !(!unit.armourSave || unit.armourSave < 2 || unit.armourSave > 7);
}

export {
    DEFAULT,
    isValidUnit,
    isValidForCharge,
    isValidForShooting,
    isValidForCombat,
    isValidForCombatTarget,
    isValidForShootingTarget
};