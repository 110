import {CHARGE, COMBAT, SHOOTING} from "../crunchers/Modes.js";
import {isNumber} from "chart.js/helpers";

const UnitForm = ({mode, type, unit, handleUnitChange}) => {
    const characteristicsVisibleInMode = {
        attacker: {
            size: [],
            movement: [CHARGE],
            weaponSkill: [COMBAT],
            ballisticSkill: [SHOOTING],
            strength: [COMBAT, SHOOTING],
            toughness: [],
            wounds: [],
            initiative: [],
            attacks: [COMBAT, SHOOTING],
            leadership: [],
            armourSave: [],
        },
        target: {
            size: [],
            movement: [],
            weaponSkill: [COMBAT],
            ballisticSkill: [],
            strength: [],
            toughness: [COMBAT, SHOOTING],
            wounds: [],
            initiative: [],
            attacks: [],
            leadership: [],
            armourSave: [COMBAT, SHOOTING],
        }
    };

    const isHidden = (characteristic) => {
        return !characteristicsVisibleInMode[type][characteristic].includes(mode);
    }

    const parseValidNumber = (input) => {
        if (isNumber(input)) {
            return parseInt(input);
        } else {
            return '';
        }
    }

    return (
        <div>
            <div className="grid">
                <div hidden={isHidden('movement')}>
                    <input name="movement"
                           pattern="[0-9]*"
                           value={unit.movement}
                           aria-invalid={unit.movement < 1 || unit.movement > 10}
                           onChange={e => handleUnitChange({...unit, movement: parseValidNumber(e.target.value)})}/>
                    <small>Movement</small>
                </div>
                <div hidden={isHidden('weaponSkill')}>
                    <input name="weaponSkill"
                           pattern="[0-9]*"
                           value={unit.weaponSkill}
                           aria-invalid={unit.weaponSkill < 1 || unit.weaponSkill > 10}
                           onChange={e => handleUnitChange({...unit, weaponSkill: parseValidNumber(e.target.value)})}/>
                    <small>Weapon Skill</small>
                </div>
                <div hidden={isHidden('ballisticSkill')}>
                    <input name="ballisticSkill"
                           pattern="[0-9]*"
                           value={unit.ballisticSkill}
                           aria-invalid={unit.ballisticSkill < 1 || unit.ballisticSkill > 10}
                           onChange={e => handleUnitChange({...unit, ballisticSkill: parseValidNumber(e.target.value)})}/>
                    <small>Ballistic Skill</small>
                </div>
                <div hidden={isHidden('strength')}>
                    <input name="strength"
                           pattern="[0-9]*"
                           value={unit.strength}
                           aria-invalid={unit.strength < 1 || unit.strength > 10}
                           onChange={e => handleUnitChange({...unit, strength: parseValidNumber(e.target.value)})}/>
                    <small>Strength</small>
                </div>
                <div hidden={isHidden('toughness')}>
                    <input name="toughness"
                           pattern="[0-9]*"
                           value={unit.toughness}
                           aria-invalid={unit.toughness < 1 || unit.toughness > 10}
                           onChange={e => handleUnitChange({...unit, toughness: parseValidNumber(e.target.value)})}/>
                    <small>Toughness</small>
                </div>
                <div hidden={isHidden('attacks')}>
                    <input name="attacks"
                           pattern="[0-9]*"
                           value={unit.attacks}
                           aria-invalid={unit.attacks < 1}
                           onChange={e => handleUnitChange({...unit, attacks: parseValidNumber(e.target.value)})}/>
                    <small>Attacks</small>
                </div>
                <div hidden={isHidden('armourSave')}>
                    <input name="armourSave"
                           pattern="[0-9]*"
                           value={unit.armourSave}
                           aria-invalid={unit.armourSave < 2 || unit.armourSave > 7}
                           onChange={e => handleUnitChange({...unit, armourSave: parseValidNumber(e.target.value)})}/>
                    <small>Armour Save</small>
                </div>
            </div>
        </div>
    )
};

export default UnitForm;