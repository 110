import {useEffect, useState} from "react";

import '../css/Cruncher.scss';
import UnitForm from "./UnitForm.js";
import ModifiersForm from "./ModifiersForm.js";
import {CHARGE, COMBAT, SHOOTING} from "../crunchers/Modes.js";
import {
    DEFAULT,
    isValidForCharge,
    isValidForCombat,
    isValidForShooting,
    isValidForCombatTarget,
    isValidUnit, isValidForShootingTarget
} from "../crunchers/Units.js";
import {
    ChargeCruncher,
    ShootingCruncher,
    CombatCruncher,
    toShootingHit,
    toWound,
    toCombatHit
} from "../crunchers/Cruncher.js";
import {MODIFIERS} from "../crunchers/Modifiers.js";
import ProbabilityChart from "./ProbabilityChart.js";
import {valueOrZero} from "../utils/utils.js";

const ITERATIONS = 10000;

const Cruncher = () => {
    const [mode, setMode] = useState(CHARGE);
    const [attacker, setAttacker] = useState(DEFAULT);
    const [target, setTarget] = useState(DEFAULT);
    const [modifiers, setModifiers] = useState({});
    const [chartDiscreteValues, setChartDiscreteValues] = useState([]);
    const [chartCumulativeValues, setChartCumulativeValues] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [median, setMedian] = useState('');
    const [score, setScore] = useState(0.0);

    function crunch(cruncher, inputMods) {
        const discreteFrequency = {};
        const mods = inputMods.filter(m => modifiers[m.slug]).flatMap(m => m.modifiers);
        for (let i = 0; i < ITERATIONS; i++) {
            let value = cruncher(attacker, target, mods).value;
            discreteFrequency[value] = valueOrZero(discreteFrequency[value]) + 1.0;
        }

        const cumulativeFrequency = {};
        const labels = Object.keys(discreteFrequency);
        for (const l1 of labels) {
            for (const l2 of labels) {
                if (parseInt(l1) <= parseInt(l2)) {
                    cumulativeFrequency[l1] = valueOrZero(cumulativeFrequency[l1]) + discreteFrequency[l2];
                }
            }
        }

        let median = '';
        for (const l1 of labels) {
            if (cumulativeFrequency[l1] >= Math.ceil((ITERATIONS + 1) / 2)) {
                median = l1;
            }
        }

        let score = 0.0;
        for (const l1 of labels) {
            score += discreteFrequency[l1] / ITERATIONS * parseInt(l1);
        }

        setMedian(median);
        setScore(Math.round(score * 1000) / 1000);
        setChartLabels(labels);
        setChartDiscreteValues(Object.values(discreteFrequency).map(v => v * 100.0 / ITERATIONS));
        setChartCumulativeValues(Object.values(cumulativeFrequency).map(v => v * 100.0 / ITERATIONS));
    }

    useEffect(() => {
        if (mode === CHARGE && isValidForCharge(attacker)) {
            crunch(ChargeCruncher, MODIFIERS[CHARGE]);
        } else if (mode === COMBAT && isValidForCombat(attacker) && isValidForCombatTarget(target)) {
            crunch(CombatCruncher, MODIFIERS[COMBAT])
        } else if (mode === SHOOTING && isValidForShooting(attacker) && isValidForShootingTarget(target)) {
            crunch(ShootingCruncher, MODIFIERS[SHOOTING]);
        } else {
            setMedian('0');
            setScore(0.0);
            setChartLabels([]);
            setChartDiscreteValues([]);
            setChartCumulativeValues([]);
        }
    }, [mode, attacker, target, modifiers])

    return (
        <div className={"container-fluid"}>
            <div role="group" className={'small-group'}>
                <button className={mode !== CHARGE ? "background" : undefined}
                        onClick={() => setMode(CHARGE)}>
                    Charge
                </button>
                <button className={mode !== COMBAT ? "background" : undefined}
                        onClick={() => setMode(COMBAT)}>
                    Combat
                </button>
                <button className={mode !== SHOOTING ? "background" : undefined}
                        onClick={() => setMode(SHOOTING)}>
                    Shooting
                </button>
            </div>
            <div className={"grid cruncher-container"}>
                <div>
                    <p>Attacking Unit</p>
                    <UnitForm mode={mode}
                              type={"attacker"}
                              unit={attacker}
                              handleUnitChange={(unit) => setAttacker(unit)}/>
                    <p>Modifiers</p>
                    <ModifiersForm mode={mode}
                                   modifiers={modifiers}
                                   handleModifiersChange={modifiers => setModifiers(modifiers)}/>
                    <p hidden={mode === CHARGE}>Target Unit</p>
                    <UnitForm mode={mode}
                              type={"target"}
                              unit={target}
                              handleUnitChange={(unit) => setTarget(unit)}/>
                </div>
                <div>
                    <ProbabilityChart labels={chartLabels} cumulative={chartCumulativeValues}
                                      discrete={chartDiscreteValues}/>
                    <p>Median: {median}</p>
                    <p>Score: {score}</p>
                    {mode === SHOOTING && isValidUnit(attacker) && isValidUnit(target) && <>
                        <p>To Hit: {toShootingHit(attacker)}</p>
                        <p>To Wound: {toWound(attacker, target)}</p>
                    </>}
                    {mode === COMBAT && isValidUnit(attacker) && isValidUnit(target) && <>
                        <p>To Hit: {toCombatHit(attacker, target)}</p>
                        <p>To Wound: {toWound(attacker, target)}</p>
                    </>}
                </div>
            </div>
        </div>
    );
};

export default Cruncher;