import React, {useState, useEffect} from 'react';
import PHASES from '../utils/Phases.js';
import {Timer} from "../utils/Timer.js";
import humanizeDuration from 'humanize-duration'

const loadGamePhases = () => {
    let value = localStorage.getItem('game_phases');
    if (value) {
        return JSON.parse(value);
    } else {
        return {
            turn: 0,
            phase: 0,
            activePlayer: '',
            firstPlayer: '',
            secondPlayer: ''
        }
    }
}

const game = loadGamePhases();
const timer = Timer();

function PhaseGuide() {
    const [turn, setTurn] = useState(game.turn);
    const [phase, setPhase] = useState(game.phase);
    const [activePlayer, setActivePlayer] = useState(game.activePlayer);
    const [firstPlayer, setFirstPlayer] = useState(game.firstPlayer);
    const [secondPlayer, setSecondPlayer] = useState(game.secondPlayer);
    const [gameTime, setGameTime] = useState(Infinity);
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        localStorage.setItem('game_phases', JSON.stringify({
            turn: turn,
            phase: phase,
            activePlayer: activePlayer,
            firstPlayer: firstPlayer,
            secondPlayer: secondPlayer
        }));
    }, [turn, phase, activePlayer, firstPlayer, secondPlayer]);

    function nextTurn(e) {
        e.preventDefault();
        if (turn === 0 && gameTime !== Infinity) {
            timer.setTime(gameTime);
            timer.setCallback((timeLeft) => setTimeLeft(humanizeDuration(timeLeft * 1000)));
        }

        setTurn(turn + 0.5);
        setPhase(0);
        if (activePlayer && firstPlayer === activePlayer) {
            setActivePlayer(secondPlayer);
            if (gameTime !== Infinity) {
                timer.timeSecondPlayer();
            }
        } else {
            setActivePlayer(firstPlayer);
            if (gameTime !== Infinity) {
                timer.timeFirstPlayer();
            }
        }
    }

    const previousTurn = () => {
        setTurn(turn - 0.5);
        setPhase(3);
        if (activePlayer && firstPlayer === activePlayer) {
            setActivePlayer(secondPlayer);
        } else {
            setActivePlayer(firstPlayer);
        }
    }

    function nextPhase(e) {
        e.preventDefault();
        setPhase(phase + 1);
    }

    function previousPhase(e) {
        e.preventDefault();
        if (phase > 0) {
            setPhase(phase - 1);
        } else {
            previousTurn();
        }
    }

    function resetGame(e) {
        e.preventDefault();
        setTurn(0);
        setPhase(0);
        setActivePlayer('');
        setFirstPlayer('');
        setSecondPlayer('');
        if (gameTime !== Infinity) {
            timer.clear();
        }
    }

    const currentPhase = PHASES[phase];

    function isGameEnd() {
        return phase === 3 && turn === 6;
    }

    function isNotFirstGamePhase() {
        return !(phase === 0 && turn === 0.5);
    }

    function isLastPhase() {
        return phase === 3 && turn < 6;
    }

    function isNotLastPhase() {
        return phase < 3 && turn <= 6;
    }

    return (
        <article>
            <header>Phase guide</header>
            {
                turn === 0 ?
                    <div>
                        <input type="text" name="firstPlayer" placeholder="First player name" value={firstPlayer}
                               onChange={e => setFirstPlayer(e.target.value)} aria-label="Text"/>
                        <input type="text" name="secondPlayer" placeholder="Second player name" value={secondPlayer}
                               onChange={e => setSecondPlayer(e.target.value)} aria-label="Text"/>
                        <input type="number" name="gameTime" placeholder="Game Time (minutes)" value={gameTime}
                               onChange={e => setGameTime(e.target.value)} min={0}/>
                    </div> :
                    <div>
                        {isGameEnd() && <p>End of the game!!!</p>}
                        <h3>Active player: {activePlayer}</h3>
                        {timeLeft && <h4>Time left: {timeLeft}</h4>}
                        <small>Turn: {Math.ceil(turn)} / 6</small>
                        <progress value={turn} max="6"/>
                        <h4>Phase: {currentPhase.name}</h4>
                        <p>{currentPhase.description}</p>
                        <ol>
                            {currentPhase.subphases.map(subphase => (
                                <li key={subphase.name}>
                                    <h5>{subphase.name}</h5>
                                    <small>{subphase.description}</small>
                                </li>
                            ))}
                        </ol>

                    </div>
            }
            <footer>
                {
                    turn === 0 ?
                        <div className={"grid"}>
                            <button onClick={nextTurn} disabled={!firstPlayer || !secondPlayer}>Start a game</button>
                        </div> :
                        <div className={"grid"}>
                            <button onClick={resetGame}>Reset game</button>
                            {isNotFirstGamePhase() && <button onClick={previousPhase}>Previous phase</button>}
                            {isNotLastPhase() && <button onClick={nextPhase}>Next phase</button>}
                            {isLastPhase() && <button onClick={nextTurn}>Next turn</button>}
                        </div>
                }
            </footer>
        </article>
    );
}

export default PhaseGuide;