import React from 'react';

export default function Modal(props) {
    const handleClose = (e) => {
        e.preventDefault();
        props.onClose();
    }

    return (
        <dialog onClick={handleClose} open={props.open}>
            <article>
                <header>
                    <button aria-label="Close" rel="prev" onClick={handleClose}/>
                    <h3>{props.title}</h3>
                </header>
                {props.children}
            </article>
        </dialog>
    );
}