import {random} from "../utils/Random.js";

const DICE_RE = /([0-9]*)d([0-9]+)/;
const FUNCTION_RE = /([a-z_]+)(\(?.*\)?)/;
const OPERATOR_RE = /([+-]?)([0-9]+)/;

const roll = (dices, maxRoll) => {
    const rolls = [];
    for (let i = 0; i < dices; i++) {
        rolls.push({roll: random(1, maxRoll), modifier: 0});
    }
    return rolls;
};

const reroll = (previousRolls, maxRoll) => {
    const rolls = [];
    for (let i = 0; i < previousRolls.length; i++) {
        rolls.push({roll: random(1, maxRoll) + previousRolls[i].modifier, modifier: 0});
    }
    return rolls;
}

const evaluateFunction = (name, context, params) => {
    let fns = {
        discard_low: () => {
            let amount = parseInt(params[0]);
            context.rolls.sort((r1, r2) => (r1.roll + r1.modifier) - (r2.roll + r2.modifier));
            context.rolls = context.rolls.slice(-amount);
        },
        discard_high: () => {
            let amount = parseInt(params[0]);
            context.rolls.sort((r1, r2) => (r1.roll + r1.modifier) - (r2.roll + r2.modifier));
            context.rolls = context.rolls.slice(0, amount);
        },
        filter: () => {
            let value = parseInt(params[0]);
            context.rolls = context.rolls.filter((roll) => roll.roll + roll.modifier >= value);
        },
        discard: () => {
            let value = parseInt(params[0]);
            context.rolls = context.rolls.filter((roll) => roll.roll + roll.modifier < value);
        },
        sum: () => {
            context.value += context.rolls.map(roll => roll.roll + roll.modifier).reduce((v1, v2) => v1 + v2);
        },
        count: () => {
            context.value = context.rolls.length
        },
        modify: () => {
            let value = parseInt(params[0]);
            context.rolls = context.rolls.map(roll => {
                return {roll: roll.roll + value, modifier: roll.modifier};
            });
        },
        modifier: () => {
            let modifier = parseInt(params[0]);
            let filter = parseInt(params[1]);
            context.rolls = context.rolls.map(roll => {
                if (roll.roll + roll.modifier >= filter) {
                    return {roll: roll.roll, modifier: roll.modifier + modifier};
                } else {
                    return {roll: roll.roll, modifier: roll.modifier};
                }
            });
        },
        reroll: () => {
            context.rolls = reroll(context.rolls, 6) // TODO maybe different max
        }
    }

    if (fns[name] !== undefined) {
        return fns[name]();
    } else {
        throw Error(`Unrecognized function name '${name}'.`);
    }
};

const evaluateStep = (step, context) => {
    let dices = step.match(DICE_RE);
    if (dices) {
        let amount = parseInt(dices[1]);
        let maxRoll = parseInt(dices[2]);
        context.rolls = context.rolls.concat(roll(amount, maxRoll));
        return context;
    }

    let fn = step.match(FUNCTION_RE);
    if (fn) {
        let params = fn[2].slice(1, -1).split(',').map(param => param.trim())
        evaluateFunction(fn[1], context, params);
        return context;
    }

    let operator = step.match(OPERATOR_RE);
    if (operator) {
        let op = operator[1];
        let modifier = parseInt(operator[2]);
        if (op === '+') {
            context.value += modifier;
        }
        if (op === '-') {
            context.value -= modifier;
        }
        return context;
    }

    return context;
};

const evaluateFormula = (formula) => {
    let context = {
        rolls: [],
        value: 0
    };
    formula.split('>').forEach((step) => {
        evaluateStep(step, context);
    });
    return context;
};

function FormulaBuilder() {
    this.steps = [];

    this.setStep = function (idx, step) {
        this.steps[idx] = step;
        return this;
    }

    this.build = function () {
        return this.steps.filter(step => step).join('>');
    }
}

const ChargeCruncher = (attacker, ignore, modifiers) => {
    let formulaBuilder = new FormulaBuilder()
        .setStep(0, '2d6')
        .setStep(10, 'discard_low(1)')
        .setStep(100, 'sum')
        .setStep(110, '+' + attacker.movement);

    modifiers.forEach(modifier => formulaBuilder.setStep(modifier.idx, modifier.formula));

    return evaluateFormula(formulaBuilder.build());
};

// re-roll high BS, low BS
const SHOOT_HIT = {
    1: 6,
    2: 5,
    3: 4,
    4: 3,
    5: 2,
    6: 2,
    7: 2,
    8: 2,
    9: 2,
    10: 2,
}

const toShootingHit = (attacker) => {
    return `${SHOOT_HIT[attacker.ballisticSkill]}+`
}

const TO_WOUND_TABLE = [
    [4, 5, 6, 6, 6, 6, 7, 7, 7, 7],
    [3, 4, 5, 6, 6, 6, 6, 7, 7, 7],
    [2, 3, 4, 5, 6, 6, 6, 6, 7, 7],
    [2, 2, 3, 4, 5, 6, 6, 6, 6, 7],
    [2, 2, 2, 3, 4, 5, 6, 6, 6, 6],
    [2, 2, 2, 2, 3, 4, 5, 6, 6, 6],
    [2, 2, 2, 2, 2, 3, 4, 5, 6, 6],
    [2, 2, 2, 2, 2, 2, 3, 4, 5, 6],
    [2, 2, 2, 2, 2, 2, 2, 3, 4, 5],
    [2, 2, 2, 2, 2, 2, 2, 2, 3, 4],
];

const toWound = (attacker, target) => {
    return `${TO_WOUND_TABLE[attacker.strength][target.toughness]}+`
}

const ShootingCruncher = (attacker, target, modifiers) => {
    let formulaBuilder = new FormulaBuilder()
        .setStep(0, attacker.size * attacker.attacks + 'd6')
        .setStep(10, `filter(${toShootingHit(attacker)})`) // to_hit
        .setStep(100, 'reroll')
        .setStep(110, `filter(${toWound(attacker, target)}+)`) // to_wound
        .setStep(200, 'reroll')
        .setStep(210, `discard(${target.armourSave}+)`) // armour_save
        .setStep(400, 'count');

    modifiers.forEach(modifier => formulaBuilder.setStep(modifier.idx, modifier.formula));

    return evaluateFormula(formulaBuilder.build());
};

const TO_HIT_TABLE = [
    [4, 4, 5, 5, 5, 5, 5, 5, 5, 5], // 1
    [3, 4, 4, 4, 5, 5, 5, 5, 5, 5], // 2
    [2, 3, 4, 4, 4, 4, 5, 5, 5, 5], // 3
    [2, 3, 3, 4, 4, 4, 4, 4, 5, 5], // 4
    [2, 2, 3, 3, 4, 4, 4, 4, 4, 4], // 5
    [2, 2, 3, 3, 3, 4, 4, 4, 4, 4], // 6
    [2, 2, 2, 3, 3, 3, 4, 4, 4, 4], // 7
    [2, 2, 2, 3, 3, 3, 3, 4, 4, 4], // 8
    [2, 2, 2, 2, 3, 3, 3, 3, 4, 4], // 9
    [2, 2, 2, 2, 3, 3, 3, 3, 3, 4], // 10
];

const toCombatHit = (attacker, target) => {
    return `${TO_HIT_TABLE[attacker.weaponSkill - 1][target.weaponSkill - 1]}+`
}

const CombatCruncher = (attacker, target, modifiers) => {
    let formulaBuilder = new FormulaBuilder()
        .setStep(0, attacker.size * attacker.attacks + 'd6')
        .setStep(10, `filter(${toCombatHit(attacker, target)})`) // to_hit
        .setStep(100, 'reroll')
        .setStep(110, `filter(${toWound(attacker, target)}+)`) // to_wound
        .setStep(200, 'reroll')
        .setStep(210, `discard(${target.armourSave}+)`) // armour_save
        .setStep(400, 'count');

    modifiers.forEach(modifier => formulaBuilder.setStep(modifier.idx, modifier.formula));

    return evaluateFormula(formulaBuilder.build());
};

export {
    ChargeCruncher,
    ShootingCruncher,
    CombatCruncher,
    FormulaBuilder,
    evaluateFormula,
    evaluateStep,
    toShootingHit,
    toCombatHit,
    toWound
};