import Glide from "@glidejs/glide";
import {useImperativeHandle, useEffect, useRef, forwardRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLongArrowLeft, faLongArrowRight} from '@fortawesome/free-solid-svg-icons'

import "@glidejs/glide/dist/css/glide.core.css";

export const Carousel = forwardRef(({options, children}, ref) => {
    const slider = useRef();
    const sliderRef = useRef();

    useImperativeHandle(ref, () => sliderRef.current);

    useEffect(() => {
        slider.current = new Glide(sliderRef.current, options);

        slider.current.mount();

        return () => slider.current.destroy();
    }, [options]);

    function nextSlide(e) {
        e.preventDefault();
        slider.current.go(">");
    }

    function previousSlide(e) {
        e.preventDefault();
        slider.current.go("<");
    }

    return (
        <div className="glide" ref={sliderRef}>
            <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">{children}</ul>
            </div>
            <div className={"grid"}>
                <button onClick={previousSlide}><FontAwesomeIcon icon={faLongArrowLeft}/></button>
                <button onClick={nextSlide}><FontAwesomeIcon icon={faLongArrowRight}/></button>
            </div>
        </div>
    );
});

export const Slide = forwardRef(({children}, ref) => {
    return (
        <li className="glide__slide" ref={ref} style={{listStyle: "none"}}>
            {children}
        </li>
    );
});
