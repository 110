import React, {useState, useEffect} from 'react';
import {Carousel, Slide} from "./Carousel.js";
import {LORES, CASTING_SPELLS} from "../utils/Lores.js";

const LoreDeckViewer = ({id}) => {
    const loadLore = (id) => {
        let value = localStorage.getItem(`lore_${id}`);
        if (value) {
            return JSON.parse(value);
        } else {
            return {};
        }
    }

    const loadSpells = (id) => {
        let value = localStorage.getItem(`spells_${id}`);
        if (value) {
            return JSON.parse(value);
        } else {
            return {};
        }
    }

    const [selectedLore, setSelectedLore] = useState(loadLore(id));
    const [selectedSpells, setSelectedSpells] = useState(loadSpells(id));

    useEffect(() => {
        localStorage.setItem(`lore_${id}`, JSON.stringify(selectedLore));
        localStorage.setItem(`spells_${id}`, JSON.stringify(selectedSpells));
    }, [selectedLore, selectedSpells]);

    useEffect(() => () => {
        localStorage.removeItem(`lore_${id}`);
        localStorage.removeItem(`spells_${id}`);
    }, []);

    function isLoreSelected() {
        return Object.keys(selectedLore).length !== 0;
    }

    function isSpellSelected(spellName) {
        // noinspection RedundantConditionalExpressionJS
        return selectedSpells[spellName] ? true : false;
    }

    function handleSpellChange(e) {
        setSelectedSpells({...selectedSpells, [e.target.name]: e.target.checked})
    }

    function getSelectedSpells() {
        let selectedSpellNames = getSelectedSpellNames();
        return selectedLore.spells.filter(spell => selectedSpellNames.includes(spell.name));
    }

    function getSelectedSpellNames() {
        return Object.keys(selectedSpells).filter(key => selectedSpells[key]);
    }

    return (
        <article>
            <header>Lore Deck</header>
            <details className="dropdown">
                <summary>
                    {isLoreSelected() ? selectedLore.name : "Select Lore of Magic..."}
                </summary>
                <ul>
                    {LORES.map(lore => (
                        <li key={lore.name}>
                            <label>
                                <input type="radio" name={lore.name} checked={lore.name === selectedLore.name}
                                       onChange={() => {
                                           setSelectedLore(lore);
                                           setSelectedSpells({});
                                       }}/>
                                {lore.name}
                            </label>
                        </li>
                    ))}
                </ul>
            </details>
            {isLoreSelected() &&
                <details className="dropdown">
                    <summary>
                        {getSelectedSpellNames().length !== 0 ? getSelectedSpellNames().length + " spells" : "Select spells..."}
                    </summary>
                    <ul>
                        {selectedLore.spells.map((spell, idx) => (
                            <li key={spell.name}>
                                <label>
                                    <input type="checkbox" name={spell.name} checked={isSpellSelected(spell.name)}
                                           onChange={handleSpellChange}/>
                                    {idx}. {spell.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </details>
            }


            {isLoreSelected() &&
                <Carousel options={{rewind: false, animationDuration: 200}}>
                    {getSelectedSpells().map(spell => (
                        <Slide key={spell.name}>
                            <article>
                                <header>{spell.name}</header>
                                <table className={"striped"}>
                                    <tbody>
                                    <tr>
                                        <th scope="row"><strong>Type</strong></th>
                                        <td>{spell.type} ({CASTING_SPELLS[spell.type]})</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><strong>Casting Value</strong></th>
                                        <td>{spell.cast}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row"><strong>Range</strong></th>
                                        <td>{spell.range}</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p>{spell.description}</p>
                            </article>
                        </Slide>
                    ))}
                </Carousel>}
        </article>
    )
}

export default LoreDeckViewer;