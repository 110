import {MODIFIERS} from "../crunchers/Modifiers.js";

const ModifiersForm = ({mode, modifiers, handleModifiersChange}) => {

    return (
        <div className={"scrollable"}>
            <fieldset>
                {MODIFIERS[mode].map(modifier => (
                    <label key={modifier.slug}>
                        <input type="checkbox"
                               name={modifier.slug}
                               checked={modifiers[modifier.slug]}
                               onChange={(e) => {
                                   const mod = {};
                                   mod[modifier.slug] = e.target.checked;
                                   handleModifiersChange({...modifiers, ...mod});
                               }}/>
                        {modifier.title}
                    </label>
                ))}
            </fieldset>
        </div>
    )
};

export default ModifiersForm;