export const Timer = () => {
    let firstPlayerTimeLeft;
    let secondPlayerTimeLeft;
    let timeout;
    let callback;

    const setTime = (timeInMinutes) => {
        firstPlayerTimeLeft = timeInMinutes * 60 / 2;
        secondPlayerTimeLeft = timeInMinutes * 60 / 2;
    }

    const stop = () => {
        if (timeout) {
            clearInterval(timeout);
        }
    }

    const clear = () => {
        stop();
        callback(undefined);
    }

    const timeFirstPlayer = () => {
        stop();
        timeout = setInterval(() => {
            if (firstPlayerTimeLeft > 0) {
                firstPlayerTimeLeft--;
            }
            callback(firstPlayerTimeLeft);
        }, 1_000);
    }

    const timeSecondPlayer = () => {
        stop();
        timeout = setInterval(() => {
            if (secondPlayerTimeLeft > 0) {
                secondPlayerTimeLeft--;
            }
            callback(secondPlayerTimeLeft);
        }, 1_000);
    }

    const setCallback = (timerCallback) => {
        callback = timerCallback;
    }

    return {
        setTime: setTime,
        setCallback: setCallback,
        timeFirstPlayer: timeFirstPlayer,
        timeSecondPlayer: timeSecondPlayer,
        clear: clear
    };
}