import {React, useState} from "react";

const CookieConsent = () => {
    const [consent, setConsent] = useState(localStorage.getItem('consent'));

    const allowGtag = () => {
        window.gtag('consent', 'update', {
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
        });
    }

    const allowConsent = () => {
        localStorage.setItem('consent', 'allowed');
        allowGtag();
        setConsent('allowed');
    }

    const disallowConsent = () => {
        localStorage.setItem('consent', 'disallowed');
        window.gtag('consent', 'update', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });
        setConsent('disallowed');
    }

    if (consent === 'allowed') {
        allowGtag();
    }

    return (
        <>
            {consent === null && <div className={'cookie-consent'}>
                <div className={"container-fluid"}>
                    <strong>We value your privacy</strong>
                    <p>
                        We use cookies to enhance your experience on our website and to analyze our traffic. By using
                        this
                        site, you agree to our use of cookies, including those used for Google Analytics. These cookies
                        help
                        us understand how you interact with our website, allowing us to improve our content and
                        services.
                    </p>
                    <p>
                        By clicking "Accept All" you agree to our use of cookies. You can withdraw your consent at any
                        time
                        by adjusting your cookie settings in your browser settings.
                    </p>
                    <button onClick={disallowConsent} className={"delete"}>Reject all
                    </button>
                    <button onClick={allowConsent}>Accept all
                    </button>
                </div>
            </div>}
        </>
    );
}

export default CookieConsent;