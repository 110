const STRATEGY_PHASE = {
    name: "Strategy",
    description: "During the Strategy phase, the active player begins to enact their plans for the turn ahead. Key to this is Wizards channelling and manipulating the Winds of Magic and commanders rallying their forces through strict orders or inspiring words.",
    subphases: [
        {
            name: "Start of turn",
            description: "The rules will often call upon a player to make certain tests or perform specific actions at the start of a turn. These things are done now.",
        },
        {
            name: "Command",
            description: "Resolve any abilities of characters that are not fleeing that are used in the Command sub-phase."
        },
        {
            name: "Conjuration",
            description: "Wizards controlled by the active player can cast Enchantment or Hex spells."
        },
        {
            name: "Rally Fleeing Troops",
            description: "The active player attempts to rally their fleeing units. Any that fail continue to flee."
        }
    ]
};

const MOVEMENT_PHASE = {
    name: "Movement",
    description: "The Movement phase is broken up into several sub-phases:",
    subphases: [
        {
            name: "Declare Charges & Charge Reactions",
            description: "The active player declares charges, indicating which of their units are charging, and which enemy unit is being charged. When charged, a unit can declare one of several charge reactions: Hold, Stand & Shoot, Flee."
        },
        {
            name: "Charge Moves",
            description: "Determine charge range by rolling two D6 and discarding the lowest. Add the Charge roll to the unit's Movement characteristic, this is the charge range. If the charge range is sufficient to reach the target unit, perform a charge move."
        },
        {
            name: "Compulsory Moves",
            description: "All compulsory moves are made during this sub-phase."
        },
        {
            name: "Remaining Moves",
            description: "Any remaining units may move during this sub-phase. Wizards may cast Conveyance spells."
        }
    ]
};

const SHOOTING_PHASE = {
    name: "Shooting",
    description: "The Shooting phase is broken down into several sub-phases and is followed in full for each shooting unit, one at a time:",
    subphases: [
        {
            name: "Choose Unit & Declare Target",
            description: "A unit is selected to shoot and its target is declared."
        },
        {
            name: "Roll To Hit",
            description: "To determine if your models hit, roll To Hit by using unit's Ballistic Skill characteristic."
        },
        {
            name: "Roll To Wound & Make Armour Saves",
            description: "For each hit, make a roll To Wound."
        },
        {
            name: "Remove Casualties & Make Panic Tests",
            description: "Unsaved wounds are applied to the unit, each causing a model to lose one Wound. When a model has been reduced to zero Wounds, it is removed as a casualty. If, during the Shooting phase, a unit loses more than a quarter (25%) of the models it contained at the beginning of the Shooting phase, it makes a Panic test."
        }
    ]
};

const COMBAT_PHASE = {
    name: "Combat",
    description: "The Combat phase is broken up into several sub-phases.",
    subphases: [
        {
            name: "Choose & Fight Combat",
            description: "The active player chooses which order the combats are resolved in. Each combat must be fully resolved before moving onto the next one."
        },
        {
            name: "Calculate Combat Result",
            description: "Calculate how many 'combat result points' your unit(s) scored."
        },
        {
            name: "Break Test",
            description: "Each unit belonging to the losing side must make a Break test by rolling 2D6 and applying the difference between the winner and the loser's combat result scores as a negative modifier."
        },
        {
            name: "Follow Up & Pursuit",
            description: "Once Break tests have been made, but before any units Give Ground or make a Flee roll, the winning unit(s) must decide what they will do next: restrain and reform, follow up or pursue."
        }
    ]
};

const PHASES = [
    STRATEGY_PHASE,
    MOVEMENT_PHASE,
    SHOOTING_PHASE,
    COMBAT_PHASE
]

export default PHASES;