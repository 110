import React, {useState} from 'react';
import Modal from "./Modal.js";
import {Link} from "react-router-dom";

const HeadNav = () => {

    const [modals, setModals] = useState({
        aboutUs: false,
        credits: false
    });

    function openAboutUs(e) {
        e.preventDefault();
        setModals({...modals, aboutUs: true});
    }

    function openCredits(e) {
        e.preventDefault();
        setModals({...modals, credits: true})
    }

    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <strong>Hammer Crunch</strong><span className={'small-hidden'}> - Forge Your Path, Hammer Every Battle</span>
                    </li>
                </ul>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/cruncher">Cruncher</Link></li>
                    <li><a href="/" onClick={e => openAboutUs(e)}>About</a></li>
                    <li><a href="/" onClick={e => openCredits(e)}>Credits</a></li>
                </ul>
            </nav>
            <Modal title={"About Hammer Crunch"} open={modals.aboutUs}
                   onClose={() => setModals({...modals, aboutUs: false})}>
                <p>
                    Welcome to Hammer Crunch, your ultimate companion for dominating the battlefield in Warhammer
                    Oldworld.
                    Designed with tabletop enthusiasts in mind, Hammer Crunch streamlines your gaming experience, so
                    you can focus on what truly matters—strategizing and crushing your opponents.
                </p>

                <h6>Why Choose Hammer Crunch?</h6>
                <ul>
                    <li>
                        <p>
                            <strong>Effortless Turn and Phase Tracking: </strong>
                            Stay organized and in control as Hammer Crunch tracks every turn
                            and phase of your game, ensuring you never miss a critical moment.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Charge with Confidence: </strong>
                            Our advanced probability calculator gives you real-time insights into the
                            likelihood of successful charges, so you can make informed tactical decisions.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Precision in Shooting and Combat: </strong>
                            From the volley of arrows to the clash of steel, Hammer Crunch
                            calculates the potential outcomes of shooting and combat with pinpoint accuracy, giving
                            you the edge
                            in every skirmish.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Instant Spell Access: </strong>
                            Quickly access your spells and unleash magical devastation at just the right
                            moment, without fumbling through books or notes.
                        </p>
                    </li>
                </ul>
                <p>
                    Hammer Crunch is more than just a tool—it's your secret weapon for mastering the complexities of
                    Warhammer Fantasy Battle. Whether you're a seasoned general or a newcomer to the tabletop,
                    Hammer Crunch elevates your game to the next level.
                </p>
            </Modal>

            <Modal title={"Credits"} open={modals.credits} onClose={() => setModals({...modals, credits: false})}>
                <p>
                    Fantasy icons from <a href={"https://game-icons.net/"}>game-icons.net</a>
                     (<a href={"https://creativecommons.org/licenses/by/3.0/"}>CC BY 3.0</a>).
                </p>
                <p>
                    <strong>
                        This web site is completely unofficial and in no way endorsed by Games Workshop Limited.
                    </strong>
                </p>
                <p>
                    Warhammer: the Old World, Citadel, Forge World, Games Workshop, GW, Warhammer, the
                    'winged-hammer' Warhammer logo, the Chaos devices, the Chaos logo, Citadel Device, the
                    Double-Headed/Imperial Eagle device, 'Eavy Metal, Games Workshop logo, Golden Demon, Great
                    Unclean One, the Hammer of Sigmar logo, Horned Rat logo, Keeper of Secrets, Khemri, Khorne, Lord
                    of Change, Nurgle, Skaven, the Skaven symbol devices, Slaanesh, Tomb Kings, Trio of Warriors,
                    Twin Tailed Comet Logo, Tzeentch, Warhammer Online, Warhammer World logo, White Dwarf, the White
                    Dwarf logo, and all associated logos, marks, names, races, race insignia, characters, vehicles,
                    locations, units, illustrations and images from the Warhammer world are either ®, TM and/or ©
                    Copyright Games Workshop Ltd 2000-2024, variably registered in the UK and other countries around
                    the world. Used without permission. No challenge to their status intended. All Rights Reserved
                    to their respective owners.
                </p>
            </Modal>
        </div>
    );
}

export default HeadNav;