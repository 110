import React, {useState, useEffect} from 'react';
import PhaseGuide from './PhaseGuide.js';
import LoreDeckViewer from "./LoreDeckViewer.js";


function Home() {
    const [lores, setLores] = useState(parseInt(localStorage.getItem('lores') || '0'));

    useEffect(() => {
        localStorage.setItem('lores', lores.toString());
    }, [lores]);

    return (
        <div className={"container"}>
            <PhaseGuide/>
            {Array.from(Array(lores).keys()).map(i => (<LoreDeckViewer key={i} id={i}/>))}
            <div role="group" className={'small-group'}>
                <button onClick={() => setLores(lores + 1)}>Add Lore Deck</button>
                <button disabled={lores === 0} onClick={() => setLores(lores - 1)} className={"delete"}>Remove Lore Deck</button>
            </div>
        </div>
    );
}

export default Home;
