import {CHARGE, COMBAT, SHOOTING} from "./Modes.js";

const MODIFIERS = {};
MODIFIERS[CHARGE] = [
    {
        slug: 'swiftstride',
        title: 'Swiftstride',
        modifiers: [{idx: 20, formula: '1d6'}]
    },
    {
        slug: 'difficult-terrain',
        title: 'Difficult Terrain',
        modifiers: [{idx: 10, formula: 'discard_high(1)'}, {idx: 120, formula: '-1'}]
    }
];
MODIFIERS[COMBAT] = [
    {
        slug: 'poison-attacks',
        title: 'Poison Attacks',
        modifiers: [{idx: 20, formula: 'modifier(+6, 6+)'}]
    },
    {
        slug: 'armour-bane-1',
        title: 'Armour Bane (1)',
        modifiers: [{idx: 120, formula: 'modifier(-1, 6+)'}]
    },
    {
        slug: 'armour-bane-2',
        title: 'Armour Bane (2)',
        modifiers: [{idx: 120, formula: 'modifier(-2, 6+)'}]
    },
    {
        slug: 'armour-bane-3',
        title: 'Armour Bane (3)',
        modifiers: [{idx: 120, formula: 'modifier(-3, 6+)'}]
    },
    {
        slug: 'armour-piercing-1',
        title: 'Armour Piercing (1)',
        modifiers: [{idx: 121, formula: 'modifier(-1, 1+)'}]
    },
    {
        slug: 'armour-piercing-2',
        title: 'Armour Piercing (2)',
        modifiers: [{idx: 121, formula: 'modifier(-2, 1+)'}]
    },
    {
        slug: 'armour-piercing-3',
        title: 'Armour Piercing (3)',
        modifiers: [{idx: 121, formula: 'modifier(-3, 1+)'}]
    },
];
MODIFIERS[SHOOTING] = [
    // {
    //     slug: 'move-and-shoot',
    //     title: 'Move & Shoot',
    //     modifiers: [{idx: 1, formula: 'modify(-1)'}]
    // },
    // {
    //     slug: 'long-range',
    //     title: 'Firing at Long Range',
    //     modifiers: [{idx: 2, formula: 'modify(-1)'}]
    // },
    // {
    //     slug: 'stand-and-shoot',
    //     title: 'Stand & Shoot',
    //     modifiers: [{idx: 3, formula: 'modify(-1)'}]
    // },
    // {
    //     slug: 'partial-cover',
    //     title: 'Target Behind Partial Cover',
    //     modifiers: [{idx: 4, formula: 'modify(-1)'}]
    // },
    // {
    //     slug: 'full-cover',
    //     title: 'Target Behind Full Cover',
    //     modifiers: [{idx: 4, formula: 'modify(-2)'}]
    // },
    {
        slug: 'poison-attacks',
        title: 'Poison Attacks',
        modifiers: [{idx: 20, formula: 'modifier(+6, 6+)'}]
    },
    {
        slug: 'armour-bane-1',
        title: 'Armour Bane (1)',
        modifiers: [{idx: 120, formula: 'modifier(-1, 6+)'}]
    },
    {
        slug: 'armour-bane-2',
        title: 'Armour Bane (2)',
        modifiers: [{idx: 120, formula: 'modifier(-2, 6+)'}]
    },
    {
        slug: 'armour-bane-3',
        title: 'Armour Bane (3)',
        modifiers: [{idx: 120, formula: 'modifier(-3, 6+)'}]
    },
    {
        slug: 'armour-piercing-1',
        title: 'Armour Piercing (1)',
        modifiers: [{idx: 121, formula: 'modifier(-1, 1+)'}]
    },
    {
        slug: 'armour-piercing-2',
        title: 'Armour Piercing (2)',
        modifiers: [{idx: 121, formula: 'modifier(-2, 1+)'}]
    },
    {
        slug: 'armour-piercing-3',
        title: 'Armour Piercing (3)',
        modifiers: [{idx: 121, formula: 'modifier(-3, 1+)'}]
    },
];

export {MODIFIERS};