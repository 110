import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import Home from './components/Home.js';
import HeadNav from "./components/HeadNav.js";
import './index.scss'
import Cruncher from "./components/Cruncher.js";
import CookieConsent from "./components/CookieConsent.js";

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <>
                <div className={"container-fluid"}>
                    <HeadNav/>
                    <Home/>
                </div>
                <CookieConsent/>
            </>
        ),
    },
    {
        path: "/cruncher",
        element: (
            <>
                <div className={"container-fluid"}>
                    <HeadNav/>
                    <Cruncher/>
                </div>
            </>
        ),
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
        <p className={"footer"}>
            This web site is completely unofficial and in no way endorsed by Games Workshop Limited.
        </p>
    </React.StrictMode>
);