import {ReactChart} from "chartjs-react";
import {
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Tooltip
} from 'chart.js';
import React from "react";

const ProbabilityChart = ({labels, discrete, cumulative}) => {
    ReactChart.register(
        BarController,
        LineController,
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Tooltip
    );

    return (
        <ReactChart
            id="charge-crunch-chart"
            type="bar"
            data={{
                labels: labels,
                datasets: [
                    {
                        label: 'Discrete',
                        type: 'line',
                        data: discrete,
                        borderColor: 'rgb(217, 53, 38)',
                        backgroundColor: 'rgb(246, 202, 191)'
                    },
                    {
                        label: 'Cumulative',
                        type: 'bar',
                        data: cumulative,
                        backgroundColor: 'rgb(60, 113, 247)'
                    }
                ],
            }}
            options={{
                interaction: {
                    intersect: false,
                    mode: 'index',
                },
            }}
        />
    )
}

export default ProbabilityChart;
